@import url('https://fonts.googleapis.com/css?family=Montserrat');

$small: 576px;
$medium: 768px;
$large: 992px;
$xtralarge: 1200px;


body {
    margin: 0;
    padding: 0;
}

:root {
    font-family: Montserrat !important;
    --font-family: Montserrat !important;
}


.p-inputtext {
    margin-bottom: .5rem;
    height: 50px;
}

.p-carousel-items-container {
    align-items: center !important;
}

.p-carousel-items-content {
    background-color: var(--blue-100) !important;
}